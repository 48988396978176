import { useState } from 'react'
import { Link } from "react-router-dom";

import * as Icons from 'react-feather'
import * as S from './Navigation.style.js'

import Logo from '../../../src/assets/logo.svg';

const Elements = [
  {
    link: '/#home',
    title:'Home',
  },
  {
    link: '/#products',
    title:'Products',
  },
  {
    link: '/#brands',
    title:'Brands',
  },
  {
    link: '/about',
    title:'About us',
  },
]

const Products = [
  {
    title: 'Rubber Parts',
    link: 'https://catalogue.sourcewiz.co/hklq1k6',
  },
  {
    title: 'Brake Parts',
    link: 'https://catalogue.sourcewiz.co/wys6sy5',
  },
  {
    title: 'Suspension',
    link: 'https://catalogue.sourcewiz.co/9vlois1',
  },
  {
    title: 'Engine Parts',
    link: 'https://catalogue.sourcewiz.co/sajmg22',
  },
  {
    title: 'Clutch Parts',
    link: 'https://catalogue.sourcewiz.co/b2m08py',
  },
  {
    title: 'Bearings',
    link: 'https://catalogue.sourcewiz.co/banu0tm',
  },
  {
    title: 'General Parts',
    link: 'https://catalogue.sourcewiz.co/7xo1k10',
  },
]

const Navigation = () => {
  const [menu, setMenu] = useState(false);

  const ToggleMenu = () => {
    setMenu(state => !state)
  }

  return (
    <>
      {/*Navigation Bar */}

      <S.Nav>
        <S.Container>
          <S.Icon>
          <img src={Logo} />
          <S.Header>MACSONS X-PORTS</S.Header>
          </S.Icon>
          <S.MenuButton  onClick = {ToggleMenu}>
            <Icons.Menu color='#5A606B'/>
          </S.MenuButton>
          <S.LinkContainer>
            <S.Link href="/#home"> Home </S.Link>
            
            <S.Link href="/#products"> 
              Products
              <S.Dropdown>
                {Products.map((value, key) => (
                  <S.DropdownElement>
                    <S.Link href={value.link} target='_blank'>{value.title}</S.Link>
                  </S.DropdownElement>
                ))}
              </S.Dropdown>
            </S.Link>

            <S.Link href="/#brands"> Brands </S.Link>
            <S.Link href="/about"> About us </S.Link>

            {/* <S.Link>
                Products
                <S.Dropdown>
                  <ul>
                    <li>asdf</li>
                    <li>adsf</li>
                    <li>adf</li>
                    <li>asdf</li>
                  </ul>
                </S.Dropdown>
            </S.Link> */}
            <S.ContactButton href="/#contact">
                Contact us
            </S.ContactButton>
          </S.LinkContainer>
        </S.Container>
      </S.Nav>


      {/*Menu Box for small size screens*/}
      <S.MenuContainer className = {menu ? '' : 'clear'} >
        <S.Menu>
          <S.Cross onClick = {ToggleMenu}>
            <Icons.XCircle color='#5A606B'/>
          </S.Cross>
          {Elements.map((value, key) => (
                <S.MenuLink key={key} href={value.link}  onClick ={ToggleMenu}>
                  {value.title}
                </S.MenuLink> ))}
        </S.Menu>
      </S.MenuContainer>
    </>
  )
}

export default Navigation