import * as S from './Brands.style.js';
import bannerImage from '../../assets/banner.png';

const Brands = () => {
    return(
        <S.Container id="brands">
            <S.Banner>
                <img src={bannerImage} width="100%"/>
            </S.Banner>
            <S.Header>
                Brands we work for
            </S.Header>
        </S.Container>
    )
}

export default Brands;