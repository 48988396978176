import { styled } from './../../styles/theme.config.js';

export const Container = styled('div', {
    display        : 'flex',
    justifyContent : 'center',
    alignItems     : 'center',
    flexDirection  : 'column',
    width          : '100vw',
    zIndex         : -1,
    margin : '5em 0',
})

export const Header = styled('h2', {
    paddingTop : '1em',
})

export const Text = styled('p', {
    padding : '2em 2em 0 0',
    color: 'rgba(0, 0, 0, 0.7)',
    lineHeight : '32px',
    '@media only screen and (max-width : 750px)' :{
      textAlign : 'justify',
      padding : '1em 0',
    },
})

export const Image = styled('img', {
    '@media only screen and (max-width : 750px)' :{
        display : 'none',
        width : '90vw',
    },
})

export const Content = styled('div', {
    display : 'flex',
    flexDirection : 'column',
    justifyContent : 'center',
    padding : '0 2em',
    width : '70vw',
    '@media only screen and (max-width : 750px)' :{
        width : '90vw',
        padding : '0 1em',
        alignItems : 'center',
    },
})

export const Box = styled('div', {
    display : 'flex',
    margin : '5em 0',
    width : '70vw',
    '@media only screen and (max-width : 750px)' :{
        flexDirection : 'column',
        width : '90vw',
        alignItems : 'center',
        margin : '2em 0',
    },
})