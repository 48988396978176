import { styled } from './../../styles/theme.config.js';

export const Container = styled('div', {
    display        : 'flex',
    justifyContent : 'center',
    alignContent   : 'center',
    width          : '100vw',
    zIndex         : -1,
    margin : '2em 0 4em 0',
    padding : '2em',
    '@media only screen and (max-width : 750px)' :{
        flexDirection : 'column'
    },
    
})

export const Content = styled('div', {
    display        : 'flex',
    justifyContent : 'center',
    flexDirection  : 'column',
    width : '35vw',
    '@media only screen and (max-width : 750px)' :{
        width : '80vw'
    },
    
})

export const Data = styled('div', {
    display : 'flex',
    margin : '1.5em 0',
    width : '90vw',
    padding : '0 0.5e',
})

export const Header = styled('h2', {
    margin : '0.5em 0'
})

export const Text = styled('p', {
    margin : '0 1.5em',
    '@media only screen and (max-width : 750px)' :{
        width : '40vw',
        fontSize : '$1',
        textAlign : 'left',
    },
}) 

export const Link = styled('a',{})

export const Image = styled('img', {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',  
})

