import { styled } from './../../styles/theme.config.js';

export const Container = styled('div', {
    display        : 'flex',
    justifyContent : 'center',
    alignItems     : 'center',
    flexDirection  : 'column',
    width          : '100vw',
    zIndex         : -1,
    padding : '5em 0',
})

export const Header = styled('h2', {
    textAlign : 'center',
    '@media only screen and (max-width : 600px)' :{
        fontSize : '$3',
    },
})

export const Content = styled('div', {
    display : 'flex',
    flexWrap : 'wrap',
    justifyContent : 'center',
    width : '80vw',
    margin : '3em 0'
})

export const Product = styled('div', {
    display : 'flex',
    flexDirection : 'column',
    justifyContent : 'center',
    alignItems : 'center',
    marginBottom : '1em',
})

export const Image = styled('img', {
  width : '20vw',
  margin : '0.5em',
  '@media only screen and (max-width : 600px)' :{
    width : '300px',
   },
})

export const Link = styled('a', {
    textDecoration : 'none',
    color : '#F34D4D',

})

// export const Button = styled('a', {
//     display        : 'flex',
//     justifyContent : 'center',
//     alignItems     : 'center',
//     background     : '$grey',
//     fontSize       : '$2',
//     fontWeight     : '600',
//     height         : '3em',
//     padding        : '0.5em 5em',
//     margin         : '1em',
//     borderRadius   : '2px',
//     border         : 'none',
//     color          : '$white',
//     textDecoration : 'none',
//     '&:hover' : {
//       color   : '$white',
//     }
//   })

