import { styled } from './../../styles/theme.config.js';

export const Container = styled('div', {
    padding : '5em 10em',    
    "@media only screen and (max-width : 600px)": {
        padding : '3em'
    },
})

export const Image = styled('img',{
    width: '280px',
    display: 'block',
    margin: 'auto',
    padding : '0em',
    borderRadius : '3px',
    "@media only screen and (max-width : 600px)": {
        width : '70vw'
    },
})

export const Content = styled('div',{
    border : 'none !important',
    margin : 0,
    padding : 0,
})