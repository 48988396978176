import * as S from './Products.style.js';

import Product_1 from './../../assets/Product_1.png';
import Product_2 from './../../assets/Product_2.png';
import Product_3 from './../../assets/Product_3.png';
import Product_4 from './../../assets/Product_4.png';
import Product_5 from './../../assets/Product_5.png';
import Product_6 from './../../assets/Product_6.png';

const prodArray = [
    {
        imageURL : Product_1,
        link : 'https://catalogue.sourcewiz.co/hklq1k6',
    },
    {
        imageURL : Product_2,
        link : 'https://catalogue.sourcewiz.co/wys6sy5',
    },
    {
        imageURL : Product_3,
        link : 'https://catalogue.sourcewiz.co/9vlois1',
    },
    {
        imageURL : Product_4,
        link : 'https://catalogue.sourcewiz.co/sajmg22',
    },
    {
        imageURL : Product_5,
        link : 'https://catalogue.sourcewiz.co/xl9km57',
    },
    {
        imageURL : Product_6,
        link : 'https://catalogue.sourcewiz.co/banu0tm',
    },
];

const Products = () => {
    return (
        <S.Container id="products">
            <S.Header>
                Prominently we deal in these products
            </S.Header>
            <S.Content>
                {prodArray.map((product, key) => (
                    <S.Product>
                        <S.Image src={product.imageURL} target="_blank"/>
                        <S.Link href={product.link} target="_blank">View products</S.Link>
                    </S.Product>
                ))}
            </S.Content>
        </S.Container>
    )
}

export default Products;