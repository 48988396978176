import { styled } from './../../styles/theme.config.js'

export const Container = styled('div', {
  display        : 'flex',
  justifyContent : 'center',
  alignItems     : 'center',
  flexDirection  : 'column',
  height         : '100vh',
  width          : '100vw',
  zIndex         : -1,
})

export const Content = styled('div', {
    position : 'relative',
    width : "90%",
    background: '#F6F6F6',
    backgroundRepeat : 'no-repeat',
    backgroundSize : 'contain',
    height : '85vh',
    backgroundPosition : 'right',
    color : '$black',
    '@media only screen and (max-width : 600px)' :{
        backgroundPosition : 'bottom',
    },
})

export const Para = styled('div', {
    position : 'absolute',
    width : '45%',
    fontSize : '28px',
    fontWeight : 'bold',
    lineHeight : '2em',
    color : '#1D2624',
    zIndex : 99,
    userSelect : 'none',
    transform : 'translate(2em, 3em)',
    '@media only screen and (max-width : 600px)' :{
        fontSize : '$2',
        width : '70%',
    },

})

export const LogoStamp = styled('div', {
    position : 'absolute',
    bottom : 0,
    padding : '3em 6em',
    zIndex : 99,
    "@media only screen and (max-width : 600px)": {
        padding : '3em 3em'
    },
})

export const Logo = styled('img', {
    "@media only screen and (max-width : 600px)": {
        width : '7em',
    },
})

export const Image = styled('img',{
    position : 'absolute',
    right : 0,
    height: '100%',
    display: 'block',
    "@media only screen and (max-width : 600px)": {
        width : '100%',
        display:'inline-block !impotant'
    },
})

export const Box = styled('div', {
    position : 'relative',
    width : "90%",
    background: '#f6f6f6',
    backgroundRepeat : 'no-repeat',
    backgroundSize : 'contain',
    height : '85vh',
    backgroundPosition : 'right',
    '@media only screen and (max-width : 600px)' :{
        transform : 'translateY(3em)'
    },
})
