import globalStyle from "./styles/global.style.js";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Banner from '../src/components/Banner/Banner';
import Products from '../src/components/Products/Products';
import Brands from '../src/components/Brands/Brands';
import Contact from '../src/components/Contact/Contact';
import Carousel from '../src/components/Carousel/Carousel';

const App = () => {
  globalStyle();
  return (
        <>
          <Banner />
          <Products />
          <Brands />
          <Carousel />
          <Contact />
        </>
  );
}

export default App;
