import { styled } from './../../styles/theme.config.js';

export const Container = styled('div', {
    display        : 'flex',
    justifyContent : 'center',
    alignItems     : 'center',
    flexDirection  : 'column',
    width          : '100vw',
    zIndex         : -1,
})

export const Banner = styled('div', {
    width : '100vw',
    margin : '5em 0',
    '@media only screen and (max-width : 600px)' :{
        display : 'none',
    },
})

export const Header = styled('h2', {
    textAlign : 'center',
})

export const Brands = styled('div', {
    display : 'flex',
    flexWrap : 'wrap',
})

export const Image = styled('img', {
    width : '160px',
    margin : '0.5em',
})