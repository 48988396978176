import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import * as S from './Carousel.style.js';
import globalStyle from "./../../styles/global.style.js";
import './style.css'

import Brand_1 from '../../assets/Brand_1.png';
import Brand_2 from '../../assets/Brand_2.png';
import Brand_3 from '../../assets/Brand_3.png';
import Brand_4 from '../../assets/Brand_4.png';
import Brand_5 from '../../assets/Brand_5.png';
import Brand_6 from '../../assets/Brand_6.png';
import Brand_7 from '../../assets/Brand_7.png';
import Brand_8 from '../../assets/Brand_8.png';
import Brand_9 from '../../assets/Brand_9.png';
import Brand_10 from '../../assets/Brand_10.png';
import Brand_11 from '../../assets/Brand_11.png';
import Brand_12 from '../../assets/Brand_12.png';
import Brand_13 from '../../assets/Brand_13.png';
const brandsArray = [Brand_1, Brand_2, Brand_3, Brand_4, Brand_5, Brand_6, Brand_7, Brand_8, Brand_9, Brand_10, Brand_11, Brand_12, Brand_13];

const Carousel = () => {

    globalStyle();

    const settings = {
        dots: false,
        className: "center",
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
            }
            },
            {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
            }
          },
          {
            breakpoint: 1000,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]   
      };

    return(
        <S.Container>
            <Slider {...settings}>
                {brandsArray.map(brandImg => (
                <S.Content>
                    <S.Image src={brandImg}/>
                </S.Content>
                ))}
            </Slider>
        </S.Container>
    )
}

export default Carousel;