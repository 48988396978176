import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Layout } from "./styles/theme.config.js";
import Navigation from '../src/components/Navigation/Navigation';
import About from '../src/components/About/About';
import Footer from '../src/components/Footer/Footer';



ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Layout>
      <Navigation />
      <Routes>
        <Route path='/' element={<App />}/>
        <Route path='/home' element={<App />}/>
        <Route path='/about' element={<About />}/>
      </Routes>
      <Footer />
      </Layout>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
