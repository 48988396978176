import * as S from './Contact.style.js';
import * as Icons from 'react-feather';

import Map from '../../assets/Map.png';

import {
    StaticGoogleMap,
    Marker,
    Path,
  } from 'react-static-google-map';
  

const contactArray = [
    {
        icon : <Icons.Mail />,
        title : 'Email',
        content : 'ekyumsawhney@macsonsxports.com'
    },
    {
        icon : <Icons.Phone />,
        title : 'Contact',
        content : '+91- 9205620582'
    },
    {
        icon : <Icons.MapPin/>,
        title : 'Address',
        content : 'AG-61, Sanjay Gandhi Transport Nagar, New Delhi, 110042'
    },
]
const Contact = () => {
    return (
        <S.Container id="contact">
            <S.Content>
                <S.Header>
                    Contact us
                </S.Header>
                You can reach out to us at any of these 

                {contactArray.map((value,key) => (
                    <S.Data>
                        {value.icon}
                        <p style={{margin:'0 1em'}}>{value.title}</p>
                        <S.Text>{value.content}</S.Text>
                    </S.Data>
                ))}

            </S.Content>
            <S.Content>
                <S.Link href="https://www.google.com/maps/search/AG-65,+Sanjay+Gandhi+Transport+Nagar,+New+Delhi,+110042/@28.737347,77.1469074,17.78z" target="_blank">
                    <S.Image src={Map} width="60%" />
                </S.Link>
            </S.Content>
        </S.Container>
    )
}

export default Contact;