import * as S from './Banner.style.js';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import stampLogo from '../../assets/tecnecoLogo.png';
import background1 from '../../assets/Background_1.svg';
import background2 from '../../assets/Background_2.svg';
import background3 from '../../assets/Background_3.svg';

import Slider from "react-slick";

const backgroundImgArray = [background1, background2, background3]

function EmptyButton(props) {
    const { className, style, onClick } = props;
    return (
      <div/>
    );
  }

const Banner = () => {

    const settings = {
        dots: false,
        className: "center",
        infinite: true,
        speed: 1000,
        autoplaySpeed: 3000,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        nextArrow : <EmptyButton />, 
        prevArrow : <EmptyButton />, 

      };

    return (
        <S.Container id="home">
            <S.Content>
                <S.Para>
                Macsons X-Ports is one of the fastest-growing export houses in the automotive industry dealing in car spares, auto parts, tractor parts, automobile parts for trailers, trucks, and agricultural equipment.
                </S.Para>
                <S.LogoStamp>
                    <S.Logo src={stampLogo} />
                </S.LogoStamp> 

                <Slider {...settings}>
                    {backgroundImgArray.map(image => (
                    <S.Box>
                        <S.Image src={image}/>
                    </S.Box>
                    ))}
                </Slider>
            </S.Content>
        </S.Container>
    )
}

export default Banner;