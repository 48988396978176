import * as S from './About.style.js';
import globalStyle from "../../styles/global.style";


import About_1 from '../../assets/About_1.svg'; 
import About_2 from '../../assets/About_2.svg'; 
import About_3 from '../../assets/About_3.svg'; 

const About = () => {
    globalStyle();
    return (
        <S.Container id="about">
            <S.Content>
                <S.Header>
                    How did it start?
                </S.Header>
                <S.Text>
                    In 1996, Maninder Pal Singh (founder) began dealing in rubber parts in a modest shop in New Delhi. He learnt as much as he could about the <b>automotive industry</b> while working there, and he enjoyed offering a high-quality experience to his customers. His desire to thrive in the field, combined with his enthusiasm for giving outstanding service, secured him a few deals that he won independently. This firsthand experience of seeing a deal through from beginning to end instilled in him the confidence he needed to establish himself in the ever-expanding <b>automobile and agricultural equipment industries.</b>
                </S.Text>
                <S.Text>
                    After 25 years of operating in the industry and being a well-known name in African countries, Macsons X-ports owes its success to its humble origins, which played a major part in creating the company's vision and establishing it on solid foundations of consistency, dependability, and excellence. We began by selling <b>rubber components</b> for vehicles and have now expanded to include <b>auto parts</b> for <b>car accessories</b>, trucks, trailers, and <b>agricultural equipment</b> . This has only been possible because of our clients' regained faith and trust in us, as well as our capacity to deliver in accordance with their evolving demands and expectations. Our high quality standards and commitment to maximize client happiness have helped us achieve repeat business, which motivates our desire to march forward with the same vision.
                </S.Text>
            </S.Content>

            <S.Box>
                <S.Content>
                    <S.Header>
                        Our mission
                    </S.Header>
                    <S.Text>
                        Our experience building from ground up has helped us shape Macsons X-ports' vision based on trust, respect, and consistency. Our mission is to make this vision a reality by being a vibrant <b>automotive company</b> that serves the global market. We aspire to be a one-stop solution for all our clients' agricultural and <b>automotive spare</b> parts needs with our stringent quality procedures and broad assortment of high-quality <b>automobile spares</b> and <b>agricultural components.</b>
                    </S.Text>
                    <S.Text>
                        Since the company’s inception, we have been devoted to continuously improving and upgrading product quality at a fair price in order to deliver maximum contentment to our consumers. Being a profit-driven firm, we place a high value on client satisfaction and meeting their <b>auto parts</b> needs, as seen by our recurring revenue of more than 90%.
                    </S.Text>
                </S.Content>
                <S.Image src={About_1}/>
            </S.Box>
            
            <S.Box>
                <S.Image src={About_2}/>
                <S.Content>
                    <S.Header>
                        Employee welfare
                    </S.Header>
                    <S.Text>
                        At Macsons X-ports, we recognise that our team members are the foundation of our company and the key to our success. We strive tirelessly to ensure that our workers have a safe and clean working environment in which they may thrive to accomplish corporate and personal goals.
                    </S.Text>
                    <S.Text>
                        Along with our success, we ensure that our team members achieve their objectives. As a result, we promote open, direct, and productive communication methods. As we recognise how various employees come armed with distinct sets of talents and views, we want to advance principles of understanding, compassion, and kindness.
                    </S.Text>
                </S.Content>
            </S.Box>    
            
            <S.Box>
                <S.Content>
                    <S.Header>
                        Marketing plan
                    </S.Header>
                    <S.Text>
                        As of today, the majority of Macsons X-ports’ <b>automobile</b> operations are geared towards African countries and we plan on expanding to Europe and other parts of the globe in the near future. To ensure we successfully expand our global footprint; we continuously aim to devise and execute innovative marketing strategies to establish new mutually beneficial business relationships in the automotive <b>industry</b> across the globe.  
                    </S.Text>
                    <S.Text>
                        Macsons X-ports' operations are focused mostly on African countries for now, with plans to extend to Europe and other areas of the world in the near future. To guarantee that we continue to effectively extend our worldwide presence, we are always devising and implementing innovative marketing methods in order to establish new mutually beneficial business relationships across the globe.
                    </S.Text>
                </S.Content>
                <S.Image src={About_3}/>
            </S.Box>        
        
        </S.Container>
    )
}

export default About;