import { styled } from './../../styles/theme.config.js';

export const Nav = styled('nav', {
  position       : 'fixed',
  display        : 'flex',
  alignItems     : 'center',
  justifyContent : 'center',
  zIndex         : 1000,
  top            : 0,
  left           : 0,
  height         : '5em',
  width          : '100%',
  boxShadow :'0px 4px 44px rgba(0, 0, 0, 0.08)',
  backgroundColor : '$white'
})

export const Container = styled('div', {
  display        : 'flex',
  justifyContent : 'space-between',
  alignItems     : 'center',
  width          : '95vw',
  '@media only screen and (max-width : 600px)' :{
    width          : '85%',
    justifyContent : 'space-between',
  },
})

export const Icon = styled('div' , {
  fontSize : '$5',
  display : 'flex',
  justifyContent : 'center',
  alignItems : 'center',
})

export const Header = styled('h2', {
    color : '$primary',
    fontSize : '$4',
    margin : '0.5em',
    '@media only screen and (max-width : 600px)' :{
      fontSize : '$2',
    }
})

export const LinkContainer = styled('div', {
  display   : 'flex',
  '@media only screen and (max-width : 600px)' :{
    display : 'none',
  }
})

export const Link = styled('a', {
  color          : '#000000',
  fontSize       : '$2',
  textDecoration : 'none',
  padding         : '2em',
  fontFamily     : '$secondary',
  '&:hover' : {
    color   : '$grey',
  },
  '&:hover div' : {
    display : 'block',
  }
})

export const ContactButton = styled('a', {
  display        : 'flex',
  justifyContent : 'center',
  alignItems     : 'center',
  background     : '$grey',
  fontSize       : '$2',
  fontWeight     : '600',
  height         : '3em',
  padding : '0.5em 1.5em',
  margin         : '1em',
  borderRadius : '2px',
  border         : 'none',
  color          : '$white',
  textDecoration : 'none',
  '&:hover' : {
    color   : '$white',
  }
})

export const MenuContainer = styled('div',{
  position       : 'fixed',
  zIndex         : 999999,
  height         : '100vh',
  width          : '100vw',
  display        : 'flex',
  justifyContent : 'center',
  alignItems     : 'flex-start',
  background     : '#2A2F3440',
})

export const Menu = styled('div', {
  display        : 'flex',
  justifyContent : 'center',
  flexDirection  : 'column',
  height         : '300',
  width          : '300',
  zIndex         : 9999999,
  background     : '$white',
  padding        : '0.5em 0 1.5em 0',
  marginTop      : '20vh',
  borderRadius   : '5px',
})

export const MenuLink = styled('a', {
  color          : '$font',
  fontSize       : '$3',
  textDecoration : 'none',
  margin         : '0.5em 1.5em',
  width          : '200px',
  textAlign      : 'center',
  fontFamily     : '$primary',
})

export const MenuButton = styled('button',{
  display    : 'none',
  background : 'none',
  border     : 'none',
  '@media only screen and (max-width : 600px)' : {
    display  : 'block',
  }
})

export const Cross = styled('button', {
  background : 'none',
  border     : 'none',
  textAlign  : 'right',
  padding    : '0 0.5em',
})

export const Dropdown = styled('div', {
  display  : 'none ',
  position : 'absolute',
  width    : '12em',
  zIndex   : 1,
  margin   : '2em 0',
  boxShadow:'0px 8px 16px 0px rgba(0,0,0,0.2)',
  borderRadius    : '2px',
  backgroundColor : '$white',
  '& a' : {
    display: 'block',
    padding: '12px 16px'
  }
})

export const DropdownElement = styled('div', {
  '&:hover' : {
    backgroundColor : '#f1f1f1'
  }
})