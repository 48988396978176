import * as S from './Footer.style.js'

const brands_1 = [
    {
        title : 'Mercedes-Benz',
        link : 'https://www.mercedes-benz.com/en/',
    },
    {
        title : 'Scania',
        link : 'https://www.scania.com/',
    },
    {
        title : 'Hino',
        link : 'https://www.hino.com/',
    },
    {
        title : 'Volvo',
        link : 'https://www.volvocars.com/us',
    },
    {
        title : 'Mitsubishi',
        link : 'https://www.mitsubishicars.com/',
    },
    {
        title : 'Man',
        link : 'https://www.mantruckandbus.com/en/man.html',
    },
    {
        title : 'Ursus',
        link : 'https://www.ursus.com/en',
    },
];

const brands_2 = [
    {
        title : 'Toyota',
        link : 'https://www.toyota.com/',
    },
    {
        title : 'Nissan UD Trucks',
        link : 'https://www.udtrucks.com/',
    },
    {
        title : 'Sonalika',
        link : 'https://www.sonalika.com/',
    },
    {
        title : 'Deutz',
        link : 'https://www.deutz.com/en/',
    },
    {
        title : 'Massey Fergurson',
        link : 'https://www.masseyferguson.com/en_us.html',
    },
    {
        title : 'IVECO',
        link : 'https://www.iveco.com/Pages/Iveco-brands.html',
    },
];

const links = [
    {
        title : 'Home',
        link : '/#home'
    },
    {
        title : 'Products',
        link : '/#products'
    },
    {
        title : 'About us',
        link : '/about'
    },
    {
        title : 'Contact us',
        link : '/#contact'
    },
]

const Footer = () => {
    return(
        <S.Container>
            <S.Content>
                
                <S.Box>
                    <S.Header>MACSONS X-PORTS</S.Header>
                    <S.Text>ekyumsawhney@macsonsxports.com</S.Text>
                    <S.Text>+91- 9205620582</S.Text>
                    <S.Text>AG-61, Sanjay Gandhi Transport Nagar, New Delhi, 110042</S.Text>
                </S.Box>

                <S.Box>
                    <S.BoldText>Brands we work for</S.BoldText>
                    <S.Content>
                        <S.BrandBox>{brands_1.map((value, key) => <S.ListText href={value.link} target="blank">{value.title}</S.ListText>)}</S.BrandBox>
                        <S.BrandBox>{brands_2.map((value, key) => <S.ListText href={value.link} target="blank">{value.title}</S.ListText>)}</S.BrandBox>
                    </S.Content>
                </S.Box>

                <S.Box>
                    <S.BoldText>Quick links</S.BoldText>
                    {links.map((value,key) => (
                        <S.ListText href={value.link}>{value.title}</S.ListText>
                    ))}
                </S.Box>

            </S.Content>
        </S.Container>
    )
}

export default Footer;